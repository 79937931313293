@import "./withdrawAccount.scss";

.account_wrap {
  .container {
    max-width: 900px;

    .account_inner_wrap {
      background-color: var(--card-color);
      // margin: 60px auto 80px;
      box-shadow:
        0 6px 8px -6px #14171a1f,
        0 8px 16px -6px #14171a14;
      border-radius: 16px;
      padding: 15px;

      .account_title {
        margin-bottom: 30px;
        color: var(--light-color);

        h6 {
          color: var(--text-color);
          font-size: 16px;
          font-weight: 400;
          margin: 0;

          svg {
            cursor: pointer;
          }

          .text_copied {
            font-size: 12px;
            background: var(--secondary-color);
            padding: 4px;
            border-radius: 5px;
            box-shadow: 0px 0px 10px 0px #3a3a3b;
            position: relative;
            top: -23px;
            left: -35px;
          }

          span {
            word-wrap: break-word;
          }
        }
      }

      .account_tabs {
        ul {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0px;
          gap: 5px;
          background-color: var(--secondary-color);
          border-radius: 10px;
          margin-bottom: 10px;

          li {
            list-style: none;
            width: 100%;
            text-align: center;

            a {
              padding: 8px;
              display: block;
              text-decoration: none;
              position: relative;
              font-weight: 500;
              font-size: 14px;
              color: var(--text-color);
              position: relative;
              transition: all 0.5s ease;
              border-radius: 10px;

              &:hover {
                background-color: #07487038;
              }

              &.active {
                box-shadow:
                  0 0 8px rgba(20, 23, 26, 0.0784313725),
                  0 0 4px rgba(20, 23, 26, 0.0392156863);
                color: var(--primary-color);
                background-color: var(--button-color);

                &::after {
                  position: absolute;
                  height: 3px;
                  left: 50%;
                  bottom: 0px;
                  content: "";
                  width: 20%;
                  background: var(--primary-color);
                  transform: translate(-50%);
                  border-radius: 3px 3px 0px 0px;
                }
              }
            }
          }
        }

        // .nav-tabs {
        //     background-color: var(--secondary-color);
        //     // padding: 5px;
        //     border: none;
        //     border-radius: 10px;
        //     width: 100%;
        //     margin-bottom: 10px;

        //     .nav-item {
        //         width: 50%;

        //         .nav-link {
        //             width: 100%;
        //             border: none;
        //             border-radius: 10px;
        //             color: var(--text-color);
        //             font-weight: 500;
        //             font-size: 14px;
        //             padding: 12px 15px;

        //             &:hover {
        //                 background-color: transparent;
        //                 color: var(--primary-color);
        //             }

        //             &.active {
        //                 color: var(--primary-color);
        //                 border: none;
        //                 // border-radius: 12px;
        //                 font-weight: 500;
        //                 // box-shadow: 0 0 8px #14171a14, 0 0 4px #14171a0a;
        //                 background-color: transparent;
        //                 font-size: 14px;
        //                 position: relative;

        //                 &::after {
        //                     position: absolute;
        //                     height: 3px;
        //                     left: 50%;
        //                     bottom: 0px;
        //                     content: "";
        //                     width: 20%;
        //                     background: var(--primary-color);
        //                     transform: translate(-50%);
        //                     border-radius: 3px 3px 0px 0px;
        //                 }
        //             }
        //         }
        //     }
        // }
      }
    }
  }
}
